.Background {
    display: flex;
    justify-content: center; 
    align-items: center; 
    flex-direction: column;   
    height: 100vh;
    width: 100vw;
}

.YellowWindow.HomeYellowWindow {
    position: absolute;
    display: block;
    height: 50vh; 
    width: 55vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    cursor: pointer;
    min-width: 400px;
}

.HomeText {
    position: absolute;
    top: 110%;
    text-align: center;
    width: 150%;
    left: -25%;
    font-size: 25px;
}

.SnailCat {
    width: 94px;
    height: 46px;
    position: absolute;
    left: 80px;
    top: -45px;
}

.RunningCat {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
}

.Hint {
    position: absolute;
    top: -35px;
    right: 0;
    text-shadow: 2px 2px 4px #000000
}

.ScrumpTech {
    position: absolute;
    top: 10px;
    height: 80px;
    background-color: #ffff78;
    border-top: solid 3px #ffffff;
    border-left: solid 3px #353535;
    border-bottom: solid 3px #353535;
    border-right: solid 3px #ffffff;
}

.Click {
    position: absolute;
    left: -127px;
    top: 46%;
    width: 166px;
    cursor: auto;
}

 /* Portrait restyling for mobile */ 
@media (orientation: portrait) {
    .SnailCat {
        width: 71px;
        height: 35px;
        top: -36px;
        right: 180px;
    }
    .YellowWindow.HomeYellowWindow {
        height: 50vh; 
        width: 100vw;
    }
    .HomeText {
        width: 100%;
        left: 0;
    }
    .Click {
        top: 16%;
        transform: rotate(55deg);
        left: 10%;
        width: 140px;
    }
}