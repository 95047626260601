.LabelInside {
    position: relative;
    background-color: #ffff78;
    box-sizing: border-box;
    padding: 25px;
    border-top: solid 2px #ffffff;
    border-left: solid 2px #353535;
    border-bottom: solid 2px #353535;
    border-right: solid 2px #ffffff;
    grid-column: 2;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    height: 100%;
    width: 100%;
}

.LabelOutside {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 55vw;
    margin: auto;
    margin-top: 80px;
    margin-bottom: 80px;
    border-top: solid 2px #ffffff;
    border-left: solid 2px #353535;
    border-bottom: solid 2px #353535;
    border-right: solid 2px #ffffff;
    box-sizing: border-box;
}

@media (max-width: 927px) {
    .LabelInside {
        border-top: solid 1px #ffffff;
        border-left: solid 1px #353535;
        border-bottom: solid 1px #353535;
        border-right: solid 1px #ffffff;
    }
    .LabelOutside {
        border-top: solid 1px #ffffff;
        border-left: solid 1px #353535;
        border-bottom: solid 1px #353535;
        border-right: solid 1px #ffffff;
    }
}