.TopNavBar {
    position: absolute;
    top: 0;
    height: 5vh;
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
    margin-top: 10px;
  }

.MusicNote {
    position: fixed;
    left: 10px;
    top: 10px;
    height: 60px;
    width: 60px;
    cursor: pointer;
    z-index: 100;
  }
  
  .BackButton {
    position: fixed;
    right: 10px;
    top: 10px;
    height: 60px;
    width: 60px;
    cursor: pointer;
    z-index: 100;
  }

@media (min-width: 927px) {
  .MusicNote {
    height: 45px;
    width: 45px;
    left: 15px;
    top: 15px;
  }
  .BackButton {
    height: 45px;
    width: 45px;
    right: 15px;
    top: 15px;
  }
}

.LeftNavColumn {
   position: relative;
   grid-column: 1;
   z-index: 2;
}

.RightNavColumn {
    position: relative;
    grid-column: 2;
    z-index: 2;
 }

/* Portrait restyling for mobile */
@media (orientation: portrait) {
  .TopNavBar {
    height: 6vh;
  }
}