.GoesImage {
    position: absolute;
    top: 0;
    opacity: 0;
}

.FrameSlider {
    width: 100%;
}

.HiddenButton {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.LayCat {
    position: absolute;
    bottom: 0;
    left: 20%;
    z-index: 10;
    height: 40px;
}

.ContentHeight {
    height: 800px;
}

.Florida {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    display: none;
    object-fit: contain;
}

.Florida.Show {
    display: block
}