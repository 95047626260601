.AnimateContainer {
    width: 100%; /* or any specific width */
    height: 100%; /* or any specific height */
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border-bottom: solid 2px #ffffff;
    border-right: solid 2px #353535;
    border-top: solid 2px #353535;
    border-left: solid 2px #ffffff;
    background-color: #323232;
    -moz-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

.White {
    background-color: #ffffff;
}

.Animate {
    display: flex;
    width: 100%; /* or any specific width */
    height: 100%;
    position: relative;
}

.AnimationImage {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Cover {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

@media (max-width: 701px) {
    .AnimateContainer {
        height:100%;
    }
}

@media (max-width: 927px) {
    .AnimateContainer {
        border-bottom: solid 1px #ffffff;
        border-right: solid 1px #353535;
        border-top: solid 1px #353535;
        border-left: solid 1px #ffffff;
    }
}