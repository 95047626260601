.HourlyGraph {
    flex-direction: column;
}

.GraphContainer {
    position: relative;
    width: 98.5%;
    left: -1%;
    margin: 10px;
    margin-right: 20px;
    margin-left: 20px;
}

.RedCat {
    position: absolute;
    top: -44px;
    right: 20%;
    height: 52px;
}

.WhiteCat {
    position: absolute;
    top: -40px;
    right: 20%;
    height: 42px;
    z-index: 10;
}

.RadarSpacer {
    width: 20px;
}

